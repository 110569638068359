<template>
  <report-section title="氧减事件时间统计">
    <template v-slot:content>
      <div>
        <table class="table">
          <thead>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">
                氧减事件持续时间TD（秒）
              </td>
              <td class="table-row-item table-row-item-bold">次数</td>
              <td class="table-row-item table-row-item-bold">总占比</td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`TD${signal}10`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess10TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess10RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`10≤TD${signal}20`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess20TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess20RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`20≤TD${signal}30`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess30TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess30RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`30≤TD${signal}40`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess40TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess40RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`40≤TD${signal}50`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess50TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess50RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`50≤TD${signal}60`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess60TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeLess60RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`TD≥60`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeMore60TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusTimeMore60RatioStr }}
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </template>
  </report-section>
</template>
        
        <script>
import ReportSection from "@c/report/report/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";

export default {
  components: {
    ReportSection,
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },

  data() {
    return {
      signal: "<",
    };
  },
};
</script>
        
        <style lang="scss" scoped>
.table {
  border: 1px solid #707070;

  &-row {
    display: flex;
    justify-content: space-evenly;

    :nth-child(1) {
      width: 230px;
    }

    :nth-child(2) {
      width: 91px;
    }

    :nth-child(3) {
      width: 129px;
    }

    &-item {
      color: black;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #707070;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-bold {
        font-weight: 600;
      }
    }
  }
}
</style>