<template>
  <report-section title="氧减事件分布占比">
    <template v-slot:content>
      <div>
        <div class="bar-chart" :id="title"></div>
      </div>
    </template>
  </report-section>
</template>
        
  <script>
import ReportSection from "@c/report/report/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";

export default {
  components: {
    ReportSection,
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },

  data() {
    return {
      title: "oxygenMinusEventChart",
    };
  },
  mounted() {
    this.chart = this.$echarts.init(document.getElementById(this.title));
    this.drawLine();
  },

  watch: {
    reportDetailModel() {
      this.drawLine();
    },
  },

  methods: {
    drawLine() {
      this.chart.setOption({
        dataset: {
          source: [
            ["product", "时间占比"],
            ["<100", this.reportDetailModel.oxygenMinusEventLess100Ratio],
            ["<95", this.reportDetailModel.oxygenMinusEventLess95Ratio],
            ["<90", this.reportDetailModel.oxygenMinusEventLess90Ratio],
            ["<85", this.reportDetailModel.oxygenMinusEventLess85Ratio],
            ["<80", this.reportDetailModel.oxygenMinusEventLess80Ratio],
            ["<75", this.reportDetailModel.oxygenMinusEventLess75Ratio],
            ["<70", this.reportDetailModel.oxygenMinusEventLess70Ratio],
            ["<65", this.reportDetailModel.oxygenMinusEventLess65Ratio],
            ["<60", this.reportDetailModel.oxygenMinusEventLess60Ratio],

          ],
        },
        grid: {
          right: 0,
        },
        legend: {
          orient: "horizontal",
          right: 0,
        },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#BABABA",
            },
          },
        },
        yAxis: {
          max: 100,
          axisLine: {
            lineStyle: {
              color: "#BABABA",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "top",
              formatter: (value) => {
                return `${value.data[1].toFixed(1)}%`;
              },
              color: "#4474BE",
            },
          },
        ],
      });
    },
  },
};
</script>
        
  <style lang="scss" scoped>

.bar-chart {
  @include z-index-2;
  width: 450px !important;
  height: 480px;
  box-sizing: border-box;
}
</style>