<template>
  <div v-if="reportDetailModel" class="single-blood-oxygen-report">
    <p class="single-blood-oxygen-report-title">血氧监测报告</p>
    <section-time :reportDetailModel="reportDetailModel"></section-time>
    <section-user-info :reportDetailModel="reportDetailModel" @onEditUserInfoSucceed="onEditUserInfoSucceed">
    </section-user-info>
    <section-blood-oxygen :reportDetailModel="reportDetailModel"></section-blood-oxygen>
    <section-oxygen-minus-table :reportDetailModel="reportDetailModel"></section-oxygen-minus-table>
    <section-pulse :reportDetailModel="reportDetailModel"></section-pulse>
    <section-period :reportDetailModel="reportDetailModel"></section-period>
    <div style="page-break-after: always"></div>
    <section-trend-chart :reportDetailModel="reportDetailModel"></section-trend-chart>
    <div style="page-break-after: always"></div>
    <div class="single-blood-oxygen-report-section">
      <section-blood-oxygen-table :reportDetailModel="reportDetailModel"></section-blood-oxygen-table>
      <section-blood-oxygen-chart :reportDetailModel="reportDetailModel"></section-blood-oxygen-chart>
    </div>
    <div class="single-blood-oxygen-report-section">
      <section-oxygen-minus-event-table :reportDetailModel="reportDetailModel"></section-oxygen-minus-event-table>
      <section-oxygen-minus-event-chart :reportDetailModel="reportDetailModel"></section-oxygen-minus-event-chart>
    </div>
    <div style="page-break-after: always"></div>
    <div class="single-blood-oxygen-report-section">
      <section-oxygen-minus-time-table :reportDetailModel="reportDetailModel"></section-oxygen-minus-time-table>
      <section-oxygen-minus-time-chart :reportDetailModel="reportDetailModel"></section-oxygen-minus-time-chart>
    </div>
    <div class="single-blood-oxygen-report-section">
      <section-period-table :reportDetailModel="reportDetailModel"></section-period-table>
      <section-period-chart :reportDetailModel="reportDetailModel"></section-period-chart>
    </div>
    <section-suggestion :reportDetailModel="reportDetailModel" @onEditSuggestionSucceed="onEditSuggestionSucceed">
    </section-suggestion>
  </div>
</template>

<script>
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";
import SectionTime from "@c/report/report/single-blood-oxygen-report/section-time.vue";
import SectionUserInfo from "@c/report/report/single-blood-oxygen-report/section-user-info.vue";
import SectionBloodOxygen from "@c/report/report/single-blood-oxygen-report/section-blood-oxygen.vue";
import SectionOxygenMinusTable from "@c/report/report/single-blood-oxygen-report/section-oxygen-minus-table.vue";
import SectionPulse from "@c/report/report/single-blood-oxygen-report/section-pulse.vue";
import SectionPeriod from "@c/report/report/single-blood-oxygen-report/section-period.vue";
import SectionTrendChart from "@c/report/report/single-blood-oxygen-report/section-trend-chart.vue";
import SectionBloodOxygenTable from "@c/report/report/single-blood-oxygen-report/section-blood-oxygen-table.vue";
import SectionBloodOxygenChart from "@c/report/report/single-blood-oxygen-report/section-blood-oxygen-chart.vue";
import SectionOxygenMinusEventTable from "@c/report/report/single-blood-oxygen-report/section-oxygen-minus-event-table.vue";
import SectionOxygenMinusEventChart from "@c/report/report/single-blood-oxygen-report/section-oxygen-minus-event-chart.vue";
import SectionOxygenMinusTimeTable from "@c/report/report/single-blood-oxygen-report/section-oxygen-minus-time-table.vue";
import SectionOxygenMinusTimeChart from "@c/report/report/single-blood-oxygen-report/section-oxygen-minus-time-chart.vue";
import SectionPeriodTable from "@c/report/report/single-blood-oxygen-report/section-period-table.vue";
import SectionPeriodChart from "@c/report/report/single-blood-oxygen-report/section-period-chart.vue";
import SectionSuggestion from "@c/report/report/single-blood-oxygen-report/section-suggestion.vue";
import { ReportDetailModel } from "@c/report/report/single-blood-oxygen-report/report-detail-model.js";
export default {
  components: {
    KeyValueUnit,
    SectionTime,
    SectionUserInfo,
    SectionBloodOxygen,
    SectionOxygenMinusTable,
    SectionPulse,
    SectionPeriod,
    SectionTrendChart,
    SectionBloodOxygenTable,
    SectionBloodOxygenChart,
    SectionOxygenMinusEventTable,
    SectionOxygenMinusEventChart,
    SectionOxygenMinusTimeTable,
    SectionOxygenMinusTimeChart,
    SectionPeriodTable,
    SectionPeriodChart,
    SectionSuggestion,
  },

  props: {
    selectedInfo: Object,
  },

  data() {
    return {
      reportDetailModel: undefined,
    };
  },

  async created() {
    this.reportDetailModel = await this.getBloodOxygenReportDetail();
  },

  watch: {
    async selectedInfo() {
      this.reportDetailModel = await this.getBloodOxygenReportDetail();
    },
  },

  methods: {
    async onEditSuggestionSucceed() {
      this.$toast.showGreen("编辑成功");
      this.reportDetailModel = await this.getBloodOxygenReportDetail();
    },

    async onEditUserInfoSucceed() {
      this.$toast.showGreen("编辑成功");
      this.reportDetailModel = await this.getBloodOxygenReportDetail();
    },

    async getBloodOxygenReportDetail() {
      const params = {
        reportId: this.selectedInfo.singleBloodOxygenReportId,
      };
      try {
        const data = await this.$api.getBloodOxygenReportDetail(params);
        return new ReportDetailModel(data);
      } catch {
        this.$emit("onNoReport");
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.single-blood-oxygen-report {
  width: 900px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 50px;

  &-title {
    color: black;
    font-size: 48px;
    font-weight: 600;
    line-height: 67px;
    margin-bottom: 40px;
  }

  &-section-title {
    color: black;
    font-size: 24px;
    font-weight: 600;
    line-height: 73px;
    width: 100%;
  }

  &-section {
    display: flex;
  }
}
</style>