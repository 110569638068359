<template>
  <report-section title="血氧饱和度统计">
    <template v-slot:content>
      <div>
        <table class="table">
          <thead>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">血氧饱和度范围</td>
              <td class="table-row-item table-row-item-bold">时长</td>
              <td class="table-row-item table-row-item-bold">总占比</td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">95%</td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder95TimeStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder95RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">90%</td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder90TimeStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder90RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">85%</td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder85TimeStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder85RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">80%</td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder80TimeStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder80RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">70%</td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder70TimeStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder70RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">60%</td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder60TimeStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.bloodOxygenUnder60RatioStr }}
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </template>
  </report-section>
</template>
    
    <script>
import ReportSection from "@c/report/report/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";

export default {
  components: {
    ReportSection,
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },
};
</script>
    
    <style lang="scss" scoped>
.table {
  border: 1px solid #707070;

  &-row {
    display: flex;
    justify-content: space-evenly;

    :nth-child(1){
        width: 150px;
    }

    :nth-child(2){
        width: 170px;
    }

    :nth-child(3){
        width: 128px;
    }

    &-item {
      color: black;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #707070;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-bold {
        font-weight: 600;
      }
    }
  }
}
</style>