<template>
  <div class="section-time">
    <key-value-unit
      title="开始时间"
      :value="reportDetailModel.startTimeStr"
    ></key-value-unit>
    <key-value-unit
      title="结束时间"
      :value="reportDetailModel.endTimeStr"
    ></key-value-unit>
    <key-value-unit
      title="总时间"
      :value="reportDetailModel.totalSleepTimeStr"
    ></key-value-unit>
  </div>
</template>

<script>
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";
export default {
  components: {
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },
};
</script>

<style lang="scss" scoped>
.section-time {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>