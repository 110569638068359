<template>
  <report-section title="血氧统计">
    <template v-slot:content>
      <div class="section-blood-oxygen">
        <div class="section-blood-oxygen-content">
          <key-value-unit
            title="平均血氧饱和度"
            :value="reportDetailModel.averageBloodOxygen"
          ></key-value-unit>
          <key-value-unit
            title="血氧饱和度<90%"
            :value="reportDetailModel.bloodOxygenUnder90TimeStr"
          ></key-value-unit>
        </div>
        <div class="section-blood-oxygen-content">
          <key-value-unit
            title="最低血氧饱和度"
            :value="reportDetailModel.minBloodOxygen"
          ></key-value-unit>
          <key-value-unit
            title="Ts90%"
            :value="reportDetailModel.bloodOxygenUnder90RatioStr"
          ></key-value-unit>
        </div>
      </div>
    </template>
  </report-section>
</template>
  
  <script>
import ReportSection from "@c/report/report/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";

export default {
  components: {
    ReportSection,
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },
};
</script>
  
  <style lang="scss" scoped>
/deep/.section-blood-oxygen-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .key-value-unit{
    width: 250px;
  }
}
</style>