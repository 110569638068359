<template>
  <key-unit class="editor-info-editable-item" :title="title">
    <template v-slot:content>
      <div>
        <el-date-picker
          :append-to-body="false"
          v-model="value"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
    </template>
  </key-unit>
</template>
  
  <script>
import KeyUnit from "@c/report/report/single-blood-oxygen-report/common/key-unit/key-unit.vue";
import { DatePicker } from "element-ui";
export default {
  components: {
    KeyUnit,
    elDatePicker: DatePicker,
  },

  model: {
    prop: "inputValue",
    event: "onChanged",
  },

  props: {
    title: String,
    inputValue: Date,
  },

  data() {
    return {
      value: undefined,
    };
  },

  created() {
    this.value = this.inputValue;
  },

  watch: {
    inputValue(value) {
      this.value = value;
    },

    value(value) {
      this.$emit("onChanged", value);
    },
  },
};
</script>
  