<template>
  <report-section title="脉率统计">
    <template v-slot:content>
      <div class="section-pulse-content">
        <key-value-unit
          title="平均脉率"
          :value="reportDetailModel.averagePulseStr"
        ></key-value-unit>
        <key-value-unit
          title="最大脉率"
          :value="reportDetailModel.maxPulseStr"
        ></key-value-unit>
        <key-value-unit
          title="最小脉率"
          :value="reportDetailModel.minPulseStr"
        ></key-value-unit>
      </div>
    </template>
  </report-section>
</template>
  
  <script>
import ReportSection from "@c/report/report/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";

export default {
  components: {
    ReportSection,
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },
};
</script>
  
  <style lang="scss" scoped>
.section-pulse-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>