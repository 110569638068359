import { render, staticRenderFns } from "./section-blood-oxygen-chart.vue?vue&type=template&id=60262a6e&scoped=true"
import script from "./section-blood-oxygen-chart.vue?vue&type=script&lang=js"
export * from "./section-blood-oxygen-chart.vue?vue&type=script&lang=js"
import style0 from "./section-blood-oxygen-chart.vue?vue&type=style&index=0&id=60262a6e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60262a6e",
  null
  
)

export default component.exports