<template>
  <report-section title="监测意见">
    <template v-slot:title-right>
      <edit-button :inputIsEditable="isEditable" @onEdit="onEdit" @onSave="onSave"></edit-button>
    </template>
    <template v-slot:content>
      <div class="editor">
        <div class="editor-content">
          <div id="toobar"></div>
          <div id="editor" class="editor-content-content" :class="[
            isEditable
              ? 'editor-content-editable'
              : 'editor-content-not-editable',
          ]"></div>
        </div>
        <div class="editor-info">
          <div v-if="!isEditable" class="editor-info-not-editable">
            <key-value-unit title="医生签名" :value="doctorSignature"></key-value-unit>
            <key-value-unit title="签名日期" :value="signatureDateStr"></key-value-unit>
          </div>
          <div v-else class="editor-info-editable">
            <key-unit-input class="editor-info-editable-item" title="医生签名" v-model="doctorSignature"></key-unit-input>
            <key-unit-date-picker class="editor-info-editable-item" title="签名日期" v-model="signatureDate">
            </key-unit-date-picker>
          </div>
        </div>
      </div>
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@c/report/report/single-blood-oxygen-report/common/report-section.vue";
import EditButton from "@c/report/report/single-blood-oxygen-report/common/edit-button.vue";
import wangEditor from "wangeditor";
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit.vue";
import KeyUnitInput from "@c/report/report/single-blood-oxygen-report/common/key-unit/key-unit-input.vue";
import KeyUnitDatePicker from "@c/report/report/single-blood-oxygen-report/common/key-unit/key-unit-date-picker.vue";
import { DateTool } from "@js/date-tool.js";

export default {
  components: {
    ReportSection,
    EditButton,
    KeyValueUnit,
    KeyUnitInput,
    KeyUnitDatePicker,
  },

  props: {
    reportDetailModel: Object,
  },

  data() {
    return {
      suggestContent: "",
      isEditable: false,
      editor: undefined,
      doctorSignature: undefined,
      signatureDate: undefined,
    };
  },

  mounted() {
    //创建editor
    const editor = new wangEditor("#toobar", "#editor");
    editor.config.menus = []; //去掉编辑功能
    editor.config.onchange = (newHtml) => {
      this.suggestContent = newHtml;
    };
    editor.create();
    this.editor = editor;
    this.setupData();
  },

  watch: {
    reportDetailModel() {
      this.setupData();
    },
  },

  methods: {
    onEdit() {
      this.editor.enable()
      // this.editor.$textElem.attr("contenteditable", true);
      this.isEditable = true;
    },

    onSave() {
      this.editor.disable()
      // this.editor.$textElem.attr("contenteditable", false);
      try {
        this.validateDate();
        this.saveSuggestion();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async saveSuggestion() {
      const params = {
        reportId: this.reportDetailModel.reportId,
        suggestContent: this.suggestContent,
        doctorSignature: this.doctorSignature,
        signatureDate: DateTool.milliTimestampToStr(
          this.signatureDate?.getTime()
        ),
      };
      try {
        await this.$api.saveBloodOxygenReportSuggestion(params);
        this.isEditable = false;
        this.$emit("onEditSuggestionSucceed");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    setupData() {
      //suggestContent
      this.suggestContent = this.reportDetailModel.suggestContent;
      this.editor.txt.html(this.suggestContent ?? ""); //设置初始值
      this.editor.disable()
      //doctorSignature
      this.doctorSignature = this.reportDetailModel.doctorSignature;
      //signatureDate
      if (this.reportDetailModel.signatureDate) {
        this.signatureDate = new Date(this.reportDetailModel.signatureDate);
      }
    },

    validateDate() {
      if (this.signatureDate) {
        try {
          const signatureDateTimeStamp = this.signatureDate.getTime();
          const todayTimeStamp = new Date().getTime();
          if (signatureDateTimeStamp > todayTimeStamp) {
            throw "日期不能晚于今天";
          }
        } catch (error) {
          throw error;
        }
      }
    },
  },

  computed: {
    signatureDateStr() {
      if (this.signatureDate) {
        return DateTool.milliTimestampToStr(
          this.signatureDate.getTime(),
          "YYYY-MM-DD"
        );
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  &-content {
    width: 100%;
    height: auto;
    margin-top: rem(40);
    position: relative;

    /deep/.w-e-text {
      padding: 0px;

      &-container {
        z-index: 999 !important;
      }
    }

    &-editable {
      border: 1px solid #dcdfe6;
      padding: 10px;
    }
  }

  &-info {
    &-editable {
      &-item {
        margin-top: 15px;
      }
    }

    &-not-editable {
      /deep/.key-value-unit {
        font-size: 16px;
        font-weight: 600;

        &-key {
          color: black;
        }

        &-value {
          color: black;
        }
      }
    }
  }
}
</style>
