<template>
  <report-section title="睡眠分期占比">
    <template v-slot:content>
      <div>
        <div class="bar-chart" :id="title"></div>
      </div>
    </template>
  </report-section>
</template>
            
      <script>
import ReportSection from "@c/report/report/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";

export default {
  components: {
    ReportSection,
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },

  data() {
    return {
      title: "periodChart",
      colorArray: ["#68B680", "#576AD9", "#DF7555"],
    };
  },
  mounted() {
    this.chart = this.$echarts.init(document.getElementById(this.title));
    this.drawLine();
  },

  watch: {
    reportDetailModel() {
      this.drawLine();
    },
  },

  methods: {
    drawLine() {
      this.chart.setOption({
        grid: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        color: this.colorArray,
        series: [
          {
            type: "pie",
            radius: "80%",
            data: [
              { value: this.reportDetailModel.lightMinutesSleepPercent ?? 0, name: "浅睡期" },
              { value: this.reportDetailModel.deepMinutesSleepPercent ?? 0, name: "深睡期" },
              { value: this.reportDetailModel.remMinutesSleepPercent ?? 0, name: "眼动期" },
            ].map((item, index) => {
              //设置文字颜色
              item.label = {
                color: this.colorArray[index],
              };
              return item;
            }),
          },
        ],
      });
    },
  },
};
</script>
            
<style lang="scss" scoped>
.bar-chart {
  @include z-index-2;
  width: 450px !important;
  height: 220px;
  box-sizing: border-box;
}
</style>